import React, {useState} from "react";
import axios from "axios";
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import styled from "styled-components";
import RemoveIcon from "@mui/icons-material/Remove";
import SaveIcon from "@mui/icons-material/Save";
import Button from '@mui/material/Button';

const Stock = ({removeStockTicker, data, transactStock, isLoadingPrices, reset}) => {

    const [tradeCountInput, setTradeCountInput] = useState(0);
    const [autoBuyQuantityInput, setAutoBuyQuantityInput] = useState(data.auto_buy_quantity || 0);
    const [autoBuyAtInput, setAutoBuyAtInput] = useState(data.auto_buy_at || "");
    const [lowerLimitInput, setLowerLimitInput] = useState(data.low_limit || "");
    const [upperLimitInput, setUpperLimitInput] = useState(data.high_limit || "");
    const [isUnsaved, setIsUnsaved] = useState(false);
    const [isError, setIsError] = useState(false);

    const SaveLimits = async () => {
        try {
            if (!isUnsaved) return;
            setIsError(false);
            setIsUnsaved(true);
            await axios.put(`${process.env.REACT_APP_API_URL}/holding/${data.ticker}`, {
                lowLimit: lowerLimitInput,
                highLimit: upperLimitInput,
                autoBuyAt: autoBuyAtInput,
                autoBuyQuantity: autoBuyQuantityInput
            },
            {
                headers: {
                    "user-id": 1
                }
            });
            setIsUnsaved(false);
        } catch (error) {
            console.error(error);
            setIsError(true);
        }
    };

    const Reset = () => {
        setIsError(false);
        reset();
    };

    const Buy = async () => {
        const isSuccess = await transactStock(data.ticker, parseInt(tradeCountInput));
        if (isSuccess) setTradeCountInput(0);
    };

    const Sell = async () => {
        transactStock(data.ticker, parseInt(tradeCountInput) * -1);
        setTradeCountInput(0);
    };

    function formatCurrency(inCurrency) {
        let currency = `${inCurrency}`;
        if (currency.split(".").length > 1 && currency.split(".")[1].length > 4) {
            currency = inCurrency.toFixed(4);
        }
        if (currency.split(".").length === 1 || currency.split(".")[1].length < 2) {
            currency = inCurrency.toFixed(2);
        }
        if (currency.split(".")[0].length > 3) {
            currency = `${currency.slice(0, currency.length - (4 + currency.split(".")[1].length))},${currency.slice(currency.length - (4 + currency.split(".")[1].length))}`;
        }
        return `$${currency}`;
    }

    function formatInt(inNum) {
        let num = `${inNum}`;
        if (num.length > 3) {
            num = `${num.slice(0, num.length - 3)},${num.slice(num.length - 3)}`;
        }
        return num;
    }

    return (
        <Container>
            <RowContainer>
                <Row>
                    <IconButton onClick={()=>removeStockTicker(data.ticker)} disabled={data.quantity > 0} style={{marginRight: "5px"}} size="small"><RemoveIcon /></IconButton>
                    <Ticker>{data.ticker}</Ticker>
                    <HoldingsCountDisplay><b>{data.quantity}</b></HoldingsCountDisplay>
                </Row>
                <Row>
                    <TextField value={tradeCountInput} onChange={e=>setTradeCountInput(e.target.value)} inputProps={{style: {width: "70px"}}} type="number" variant="outlined" size="small" style={{ margin: "5px 10px"}} onInput={Reset} />
                    <Button onClick={Buy} disabled={!(tradeCountInput > 0) || !data.price} size="small" color="primary" style={{marginRight: "10px"}} variant="contained">Buy</Button>
                    <Button onClick={Sell} disabled={!(tradeCountInput > 0) || tradeCountInput > data.quantity || !data.price} size="small" color="primary" style={{marginRight: "10px"}} variant="contained">Sell</Button>
                </Row>
                <Row>
                    <StockPrice disabled={isLoadingPrices}>{data.price !== null ? (<div><b>{formatCurrency(data.price)}</b>{tradeCountInput && tradeCountInput !== "0" ? <span style={{marginLeft: "5px"}}>x {formatInt(parseInt(tradeCountInput) || "0")} = <b>{formatCurrency((data.price*parseInt(tradeCountInput || 0)))}</b></span> : <span></span>}</div>) : (<div>--</div>)}</StockPrice>
                </Row>
                <Row style={{minWidth: "320px"}}>
                    <TextFieldLabel>B$</TextFieldLabel>
                    <TextField value={autoBuyAtInput} onChange={e=>setAutoBuyAtInput(e.target.value)} onInput={()=>{Reset(); setIsUnsaved(true);}} inputProps={{style: {width: "70px"}}} onKeyDown={e => {if (e.key === "Enter") {SaveLimits(); e.target.blur();}}} type="number" variant="outlined" size="small" style={{ margin: "5px 10px"}} />
                    <TextFieldLabel>B#</TextFieldLabel>
                    <TextField value={autoBuyQuantityInput} onChange={e=>setAutoBuyQuantityInput(e.target.value)} onInput={()=>{Reset(); setIsUnsaved(true);}} inputProps={{style: {width: "70px"}}} onKeyDown={e => {if (e.key === "Enter") {SaveLimits(); e.target.blur();}}} type="number" variant="outlined" size="small" style={{ margin: "5px 10px"}} />
                </Row>
                <Row style={{minWidth: "320px"}}>
                    <TextFieldLabel>L</TextFieldLabel>
                    <TextField value={lowerLimitInput} onChange={e=>setLowerLimitInput(e.target.value)} onInput={()=>{Reset(); setIsUnsaved(true);}} inputProps={{style: {width: "70px"}}} onKeyDown={e => {if (e.key === "Enter") {SaveLimits(); e.target.blur();}}} type="number" variant="outlined" size="small" style={{ margin: "5px 10px"}} />
                    <TextFieldLabel>H</TextFieldLabel>
                    <TextField value={upperLimitInput} onChange={e=>setUpperLimitInput(e.target.value)} onInput={()=>{Reset(); setIsUnsaved(true)}} inputProps={{style: {width: "70px"}}} onKeyDown={e => {if (e.key === "Enter") {SaveLimits(); e.target.blur();}}} type="number" variant="outlined" size="small" style={{ margin: "5px 10px"}} />
                    {!!isUnsaved && <IconButton onClick={SaveLimits} style={{marginRight: "5px"}} size="small"><SaveIcon style={isError ? {color:"rgb(255,150,150)"} : {}} /></IconButton>}
                </Row>
            </RowContainer>
            <Border />
        </Container>
    );
};

export default Stock;

const Container = styled.div`
    margin: 5px;
    margin-left: 0;
    display: flex;
    flex-direction: column;
`;

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
`;

const Ticker = styled.div`
    min-width: 50px;
    border: 1px solid rgb(100,100,100);
    padding: 5px;
    font-weight: bold;
    text-align: center;
    border-radius: 5px;
    background: rgb(70,70,70);
    box-shadow: 1px 1px 2px 1px rgba(0,0,0,0.1);
    margin: 5px 0;
`;

const StockPrice = styled.div`
    margin-left: 10px;
    min-width: 260px;
    ${props => props.disabled && `
        color: rgb(150,150,150);
    `}
`;

const HoldingsCountDisplay = styled.div`
    min-width: 60px;
    margin-left: 10px;
`;

const TextFieldLabel = styled.div`
    font-weight: bold;
    margin-left: 10px;
    min-width: 22px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 45px;
`;

const Border = styled.div`
    height: 1px;
    background: rgb(100,100,100);
    margin: 10px;
    margin-bottom: 0px;
    margin-top: 30px;
    align-self: stretch;
    @media only screen and (min-width: 1064px) {
        margin-top: 10px;
    }
`;