import React, {useState, useEffect, useRef} from "react";
import axios from "axios";
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import styled from "styled-components";
import Stock from "./Stock";
// import { useTheme } from '@mui/material/styles';
import RefreshIcon from "@mui/icons-material/Refresh";
import AddIcon from "@mui/icons-material/Add";
import CircularProgress from '@mui/material/CircularProgress';
import Switch from '@mui/material/Switch';
import socketIOClient from "socket.io-client";

const Home = () => {

    // const theme = useTheme();

    const autoRefreshIntervalRef = useRef(null);
    const stockTickersRef = useRef([]);

    const [transactions, setTransactions] = useState([]);
    const [stockPrices, setStockPrices] = useState([]);
    const [accountBalance, setAccountBalance] = useState(null);
    const [stockTickerInput, setStockTickerInput] = useState("");
    const [errorText, setErrorText] = useState("");
    const [isLoadingPrices, setIsLoadingPrices] = useState(false);
    const [shouldAutoRefresh, setShouldAutoRefresh] = useState(false);

    const GetBalance = async () => {
        try {
            const resp = await axios.get(`${process.env.REACT_APP_API_URL}/balance`, {
                headers: {
                    "user-id": 1
                }
            });
            setAccountBalance(resp.data.balance);
        } catch (error) {
            console.error(error);
        }
    };

    const GetHoldings = async () => {
        try {
            setErrorText("");
            const resp = await axios.get(`${process.env.REACT_APP_API_URL}/holding`, {
                headers: {
                    "user-id": 1
                }
            });
            setStockPrices(resp.data.holdings);
        } catch (error) {
            console.error(error);
            setErrorText("Something went wrong. Please try again.");
        }
    };

    const GetTransactionHistory = async () => {
        try {
            setErrorText("");
            const resp = await axios.get(`${process.env.REACT_APP_API_URL}/transaction`, {
                headers: {
                    "user-id": 1
                }
            });
            setTransactions(resp.data.transactions);
        } catch (error) {
            console.error(error);
            setErrorText("Something went wrong. Please try again.");
        }
    };

    const RefreshStockPrices = async () =>{
        try {
            if (stockPrices.length < 1) return;
            setErrorText("");
            setIsLoadingPrices(true);
            const resp = await axios.get(`${process.env.REACT_APP_API_URL}/price?tickers=${encodeURIComponent(stockTickersRef.current.join(","))}`);
            setStockPrices(prevState => {
                const newState = [...prevState];
                for (const stock of newState) {
                    stock.price = resp.data.prices[stock.ticker]?.price || null;
                }
                return newState;
            });
        } catch (error) {
            console.error(error);
            setErrorText("Something went wrong. Please try again.");
        } finally {
            setIsLoadingPrices(false);
        }
    };

    const AddTicker = async () => {
        try {
            const newTicker =  stockTickerInput.trim().toUpperCase();
            for (const price of stockPrices) {
                if (price.ticker === newTicker) {
                    setStockTickerInput("");
                    return;
                }
            }
            setStockPrices(prevState => {
                return [...prevState, { ticker: newTicker, price: null, quantity: 0}];
            });
            setStockTickerInput("");
            const holdingCreateResp = await axios.post(`${process.env.REACT_APP_API_URL}/holding/${newTicker}`, {},
            {
                headers: {
                    "user-id": 1
                }
            });
            setStockPrices(prevState => {
                const newState = [...prevState];
                for (const stock of newState) {
                    if (stock.ticker === newTicker) stock.price = holdingCreateResp?.data?.newHolding?.price || null;
                }
                return newState;
            });
        } catch (error) {
            console.error(error);
        }
    };

    const RemoveTicker = async (ticker) => {
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/holding/${ticker}`,
            {
                headers: {
                    "user-id": 1
                }
            });
            GetHoldings();
        } catch (error) {
            console.error(error);
            setErrorText("Something went wrong. Please try again.");
        }
    };

    const Reset = () => {
        setErrorText("");
    };

    const TransactStock = async (ticker, amount) => {
        try {
            setErrorText("");
            await axios.post(`${process.env.REACT_APP_API_URL}/transaction`, {
                ticker,
                quantity: amount
            },
            {
                headers: {
                    "user-id": 1
                }
            });
            GetHoldings();
            GetBalance();
            GetTransactionHistory();
            return true;
        } catch (error) {
            console.error(error);
            setErrorText(error.response?.data?.message || "Something went wrong. Please try again.");
            return false;
        }
    };

    const InitSockets = React.useCallback(() => {
        const socket = socketIOClient(process.env.REACT_APP_WEBSOCKETS_URL, { transports: ["websocket"], auth: {stonksuserid: 1} });
        socket.on("stonks-autoTransaction", () => {
            GetBalance();
            GetHoldings();
            GetTransactionHistory();
        });
        return socket;
    }, []);
    
    React.useEffect(() => {
        const socket = InitSockets();
        return () => {
            socket.disconnect();
        };
    }, [InitSockets]);

    useEffect(()=>{
        const stockTickers = [];
        for (const price of stockPrices) {
            stockTickers.push(price.ticker);
        }
        stockTickersRef.current = stockTickers;
    }, [stockPrices]);

    useEffect(()=>{
        clearInterval(autoRefreshIntervalRef.current);
        if (shouldAutoRefresh) {
            RefreshStockPrices();
            autoRefreshIntervalRef.current = setInterval(()=>{
                RefreshStockPrices();
            }, 5000);
        }
    }, [shouldAutoRefresh]);

    useEffect(()=>{
        GetBalance();
        GetHoldings();
        GetTransactionHistory();
        return () => {
            clearInterval(autoRefreshIntervalRef.current);
        };
    }, []);

    function formatCurrency(inCurrency) {
        let currency = `${inCurrency}`;
        if (currency.split(".").length > 1 && currency.split(".")[1].length > 4) {
            currency = inCurrency.toFixed(4);
        }
        if (currency.split(".").length === 1 || currency.split(".")[1].length < 2) {
            currency = inCurrency.toFixed(2);
        }
        if (currency.split(".")[0].length > 3) {
            currency = `${currency.slice(0, currency.length - (4 + currency.split(".")[1].length))},${currency.slice(currency.length - (4 + currency.split(".")[1].length))}`;
        }
        return `$${currency}`;
    }

    function formatInt(inNum) {
        let num = `${inNum}`;
        if (num.length > 3) {
            num = `${num.slice(0, num.length - 3)},${num.slice(num.length - 3)}`;
        }
        return num;
    }

    return (
        <Container>
            <HeaderRow>
                <Title>stonks</Title>
                <AccountBalance>Balance: {accountBalance !== null ? `$${accountBalance?.toFixed(2)}` : '--'}</AccountBalance>
            </HeaderRow>
            <Card>
                <CardTitle>Holdings</CardTitle>
                <CardHeader>
                    <Row>
                        <TextField size="small" placeholder={"Search"} inputProps={{style:{textTransform: "uppercase"}}} variant="outlined" value={stockTickerInput} onKeyDown={e => e.key === "Enter" && AddTicker()} style={{ marginRight: "5px", width: "100px"}} onChange={e=>setStockTickerInput(e.target.value)} onInput={Reset} />
                        <IconButton onClick={AddTicker} style={{marginRight: "10px"}} disabled={!stockTickerInput}><AddIcon /></IconButton>
                    </Row>
                    {errorText && <ErrorText>{errorText}</ErrorText>}
                </CardHeader>
                {stockPrices.length > 0 && (
                    <StockDisplayContainer>
                        <StockIconsContainer>
                            <AutoSwitchContainer>
                                <Switch checked={shouldAutoRefresh} onChange={e=>setShouldAutoRefresh(e.target.checked)} />
                                <AutoSwitchLabel>LIVE</AutoSwitchLabel>
                            </AutoSwitchContainer>
                            <IconButton onClick={RefreshStockPrices} disabled={isLoadingPrices}><RefreshIcon /></IconButton>
                            {!!isLoadingPrices && <CircularProgress color="primary" style={{marginLeft: "10px", width: "20px", height: "20px"}} />}
                        </StockIconsContainer>
                        <StocksContainer>
                            {stockPrices.map((price, index) => (
                                <Stock key={`price-${index}-${price.ticker}}`} data={price} removeStockTicker={RemoveTicker} transactStock={TransactStock} isLoadingPrices={isLoadingPrices} reset={Reset} />
                            ))}
                        </StocksContainer>
                    </StockDisplayContainer>
                )}
            </Card>
            {transactions.length > 0 && (
                <Card style={{marginBottom: "50px"}}>
                    <CardTitle>Transaction History</CardTitle>
                    <TransactionsContainer>
                        {transactions.map(transaction => (
                            <React.Fragment key={`transaction-${transaction.transaction_id}`}>
                                <TransactionRow>
                                    <Row>
                                        <TransactionTicker>{transaction.ticker}</TransactionTicker>
                                        <TransactionType style={{backgroundColor: transaction.quantity < 0 ? "rgb(50,50,100)" : "rgb(50,75,50)"}}>{transaction.quantity < 0 ? 'SELL' : 'BUY'}</TransactionType>
                                        <TransactionPrice><b>{formatCurrency(transaction.price)}</b> x {formatInt(Math.abs(transaction.quantity))} = <b>{transaction.quantity < 0 ? '+' : '-'}{formatCurrency(transaction.price * Math.abs(transaction.quantity))}</b></TransactionPrice>
                                    </Row>
                                    <Row>
                                        <TransactionDate>{(new Date(transaction.created)).toLocaleString()}</TransactionDate>
                                    </Row>
                                </TransactionRow>
                                <Border />
                            </React.Fragment>
                        ))}
                    </TransactionsContainer>
                </Card>
            )}
        </Container>
    );
};

export default Home;

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    color: white;
    background: linear-gradient(140deg, rgb(50,50,50), rgb(30,30,30) 40%);
    height: 100vh;
`;

const Title = styled.div`
    font-size: 30px;
    margin: 10px;
    margin-left: 0;
    padding-left: 10px;
    border-left: 3px solid rgb(200,200,200);
    color: rgb(200,200,200);
`;

const AccountBalance = styled.div`
    margin-right: 20px;
    font-size: 20px;
    padding-top: 10px;
`;

const Card = styled.div`
    display: flex;
    flex-direction: column;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 1px 1px 2px 1px rgba(0,0,0,0.1);
    background: rgba(70,70,70, 0.5);
    border: 1px solid rgb(100,100,100);
    padding: 10px;
`;

const CardTitle = styled.div`
    margin: 10px;
    font-weight: bold;
    color: rgb(220,220,220);
    font-size: 20px;
`;

const HeaderRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const ErrorText = styled.div`
    color: rgb(255,200,200);
    margin-top: 10px;
`;

const StockDisplayContainer = styled.div`
    margin-top: 10px;
`;

const StocksContainer = styled.div`
    margin-top: 15px;
    display: flex;
    flex-direction: column;
`;

const StockIconsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10px;
`;

const AutoSwitchContainer = styled.div`
    position: relative;
`;

const AutoSwitchLabel = styled.div`
    position: absolute;
    bottom: -2px; left: 0; right: 0;
    text-align: center;
    font-size: 8px;
`;

const CardHeader = styled.div`
    min-height: 70px;
    margin-top: 10px;
    padding-left: 10px;
`;

const TransactionsContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

const TransactionRow = styled.div`
    margin: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const TransactionTicker = styled.div`
    min-width: 50px;
    border: 1px solid rgb(100,100,100);
    padding: 5px;
    font-weight: bold;
    text-align: center;
    border-radius: 5px;
    background: rgb(70,70,70);
    box-shadow: 1px 1px 2px 1px rgba(0,0,0,0.1);
    margin: 5px 0;
`;

const TransactionType = styled.div`
    min-width: 50px;
    border: 1px solid rgb(100,100,100);
    padding: 5px;
    text-align: center;
    border-radius: 5px;
    box-shadow: 1px 1px 2px 1px rgba(0,0,0,0.1);
    margin: 5px 0;
    margin-left: 10px;
`;

const TransactionPrice = styled.div`
    margin-left: 20px;
    text-align: center;
`;

const TransactionDate = styled.div`
    text-align: center;
`;

const Border = styled.div`
    height: 1px;
    background: rgb(100,100,100);
    margin: 0px 10px;
    align-self: stretch;
`;
